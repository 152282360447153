import React from "react";
import theme from "theme";
import { Theme, Text, Span, Strong, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"portfolio"} />
		<Helmet>
			<title>
				Портфолио | Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:title"} content={"Портфолио | Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
		>
			<Components.Header />
			<Text
				margin="0px 0px 26px 0px"
				color="--darkL2"
				font="--headline2"
				width="100%"
				sm-font="--headline3"
			>
				Портфолио
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="24px"
				xl-grid-template-columns="1fr"
				max-width="2560px"
				width="100%"
			>
				<Components.CaseCard margin="0px 0 0px 0" padding="0px 0px 0px 0px">
					<Override slot="box2" />
					<Override slot="link1" href="https://quarkly.io/" text-decoration-line="initial" target="_blank">
						quarkly.io
					</Override>
					<Override slot="text">
						Quarkly
					</Override>
					<Override slot="SectionContent" xl-max-width="100%" background="#21252D" />
					<Override slot="text1">
						No-code / Low-code платформа для создания веб-сайтов и веб-приложений. Используйте всю мощь веб-дизайна и создавайте готовые веб-сайты и приложения React
					</Override>
					<Override slot="button" background="#6CFFE4" color="--darkL2" display="none" />
					<Override slot="text2">
						UX / UI дизайн, продуктовый дизайн, менеджмент
					</Override>
				</Components.CaseCard>
				<Components.CaseCard>
					<Override slot="SectionContent" />
					<Override slot="text" />
					<Override slot="text1" />
					<Override slot="text2" />
					<Override slot="text3" />
					<Override slot="link1" />
					<Override slot="image" />
					<Override slot="button" />
					<Override slot="box2" />
					<Override slot="box1" />
					<Override slot="box" />
				</Components.CaseCard>
				<Components.CaseCard padding="0px 0px 0px 0px" margin="0px 0 0px 0">
					<Override slot="text" color="white" sm-font="--headline3">
						stepFORM
					</Override>
					<Override slot="SectionContent" background="#434CFB" />
					<Override slot="text1" color="white">
						Онлайн конструктор, где вы можете создавать онлайн калькуляторы и другие типы форм для сайтов, мессенджеров и соцсетей
					</Override>
					<Override slot="text2" color="white">
						UX / UI дизайн, продуктовый дизайн, менеджмент
					</Override>
					<Override slot="text3" color="white" border-color="white">
						stepform.io
					</Override>
					<Override
						slot="link1"
						href="https://stepform.io/"
						target="_blank"
						text-decoration-line="initial"
						border-color="white"
						color="white"
						md-margin="0px 0px 26px 0px"
					>
						stepform.io
					</Override>
					<Override slot="image" display="none" />
					<Override slot="button" type="link" text-decoration-line="initial" href="/portfolio/stepform" />
					<Override
						slot="box2"
						md-flex-direction="column"
						md-justify-content="flex-start"
						md-align-items="flex-start"
						md-height="auto"
						md-min-height="auto"
						margin="auto 0px 0px 0px"
					/>
					<Override slot="box1" margin="0px 0px 40px 0px" />
					<Override slot="box" height="100%" />
				</Components.CaseCard>
				<Components.CaseCard padding="0px 0px 0px 0px" margin="0px 0 0px 0">
					<Override slot="text" color="white" sm-font="--headline3">
						<Span color="#1366CA">
							u
						</Span>
						Kit Alt
					</Override>
					<Override slot="SectionContent" background="#132026" />
					<Override slot="text1" color="white">
						Дополнение к конструктору uKit, с помощью которого пользователи Facebook и VK могут сделать сайт из своей группы или профиля
					</Override>
					<Override slot="text2" color="white">
						UX / UI дизайн, продуктовый дизайн, менеджмент
					</Override>
					<Override slot="text3" color="white" border-color="white" />
					<Override
						slot="button"
						background="#FECB2F"
						color="--darkL2"
						display="block"
						type="link"
						text-decoration-line="initial"
						href="/portfolio/ukitalt"
					/>
					<Override
						slot="link1"
						color="--light"
						text-decoration-line="initial"
						href="https://ukit.com/ru/lp/convert"
						target="_blank"
						border-color="white"
						md-margin="0px 0px 26px 0px"
					>
						ukit.com/ru/lp/convert
					</Override>
					<Override slot="image" display="none" />
					<Override
						slot="box2"
						md-align-items="flex-start"
						md-height="auto"
						md-min-height="auto"
						md-flex-direction="column"
						md-justify-content="flex-start"
					/>
					<Override slot="box1" />
					<Override slot="box" />
				</Components.CaseCard>
				<Components.CaseCard padding="0px 0px 0px 0px" margin="0px 0 0px 0">
					<Override slot="text" color="white" sm-font="--headline3">
						<Span
							color="#1366CA"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							u
						</Span>
						Kit
					</Override>
					<Override slot="SectionContent" background="#263945" />
					<Override slot="text1" color="white">
						Простой конструктор сайтов для бизнеса, где можно делать сайты без специальных навыков
					</Override>
					<Override slot="text2" color="white">
						UX / UI дизайн
					</Override>
					<Override slot="text3" color="white" border-color="white" />
					<Override
						slot="button"
						background="#FECB2F"
						color="--darkL2"
						display="block"
						type="link"
						text-decoration-line="initial"
						href="/portfolio/ukit"
					/>
					<Override
						slot="link1"
						color="--light"
						text-decoration-line="initial"
						href="https://ukit.com/"
						target="_blank"
						border-color="white"
						md-margin="0px 0px 26px 0px"
					>
						ukit.com
					</Override>
					<Override slot="image" display="none" />
					<Override
						slot="box2"
						md-flex-direction="column"
						md-justify-content="flex-start"
						md-align-items="flex-start"
						md-height="auto"
						md-min-height="auto"
					/>
					<Override slot="box1" />
				</Components.CaseCard>
				<Components.CaseCard margin="0px 0 0px 0" padding="0px 0px 0px 0px">
					<Override slot="SectionContent" background="--color-darkL2" />
					<Override slot="text" color="--light" sm-font="--headline3">
						Мини-проекты
					</Override>
					<Override slot="text1" color="--light">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Discount Hunter
						</Strong>
						{" "}— развлекательная игра для пользователей uKit
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							BigMaconomics
						</Strong>
						{" "}— шуточное мини-приложение для расчета количества бигмаков на зарплату
					</Override>
					<Override slot="text2" color="--lightD2">
						UI дизайн, иллюстрации, менеджмент, концепция
					</Override>
					<Override slot="text3" color="white" border-color="white">
						ukit.com
					</Override>
					<Override
						slot="button"
						background="--color-primary"
						color="white"
						margin="auto 0px 0px auto"
						font="normal 400 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						type="link"
						text-decoration-line="initial"
						href="/portfolio/mini-projects"
						display="block"
					>
						Посмотреть →
					</Override>
					<Override
						slot="link1"
						color="#FFF"
						text-decoration-line="initial"
						target="_blank"
						href="https://ukit.com/"
						border-color="#FFF"
						display="none"
					>
						ukit.com
					</Override>
					<Override slot="image" display="none" />
					<Override slot="box1" color="--light" />
					<Override slot="box2" />
				</Components.CaseCard>
			</Box>
			<Components.Footer />
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(31525948, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
		</RawHtml>
	</Theme>;
});